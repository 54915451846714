import React, { useRef, useState } from 'react'
import logo from '../assets/Home/logo.png';
import Navbar from '../components/Navbar';
import contactBanner from '../assets/ContactUs/contactbanner.jpg';
import { FaLocationDot } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import { send_contact_mail } from '../api/api';
import Alert from '../components/Alert';
import Lottie from "lottie-react";
import groovyWalkAnimation from "../assets/Animation/groovyWalk.json";
import { FaPhoneVolume } from "react-icons/fa6";
const ContactUs = () => {
  const [alert, setAlert] = useState({is_show: 0, type: null, message: null});
  const formRef = useRef(null);
  const [formData, setFormData] = useState(null);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };
  const mailSubmit = async (e) => {
    e.preventDefault();
    try {
      setMainLoading(true);
      const response = await send_contact_mail(formData);
      if(response.status == "success"){
        setMainLoading(false);
        setAlert({is_show: 1, type: "success", message: response.message})
        setFormData(null)
      }
      else{
        setMainLoading(false);
        setAlert({is_show: 1, type: "error", message: "something went wrong, try again!!!"})
        setFormData(null)
      }
    } catch (error) {
      setAlert({is_show: 1, type: "error", message: error})
      setMainLoading(false);
    }
  };
  const [mainLoading, setMainLoading] = useState(false)
  return (
    <div>
      {mainLoading ? 
      <Lottie animationData={groovyWalkAnimation} loop={true} className='h-screen'/> 
      :
      <div>
    <div className="relative bg-cover bg-center h-96" >
        <div className="flex justify-center flex-col items-center h-full gap-5 ">
          <h1 className='font-extrabold md:text-6xl text-2xl'>GROUPTEN PLAZZA CENTRE</h1>
          <h3 className='font-bold md:text-4xl text-xl'>NEAR CENTRAL UNIVERSITY, PERIYA</h3>
          
          <div className='flex items-center justify-center gap-2 border-t-2 border-black w-2/3 p-2 text-2xl'>
            <FaPhoneVolume />
            <h3 className='font-bold  md:text-3xl text-base'>944 65 66 663, 807 87 66 663</h3>
          </div>
        </div>
        <div className="absolute inset-0 bg-black opacity-30"></div>
<Navbar />
{alert.is_show == 1 &&
        <Alert alert={alert} setAlert={setAlert}/>
      }
        <div className="relative container mx-auto p-6 flex flex-col items-center justify-center h-full text-center text-white">
          <h1 className="text-3xl md:text-4xl lg:text-5xl font-semibold"></h1>
        </div>
      </div>
      <main className="flex-grow flex flex-col items-center justify-center bg-gray-100 p-4">
        <div className="max-w-4xl w-full bg-white p-8 rounded shadow flex md:flex-row flex-col items-center justify-center">
          <div className="md:w-1/2 w-full pr-8 border-b md:border-b-0 md:border-r border-gray-200 flex flex-col justify-center items-center">
            <h1 className="text-2xl font-bold mb-6">WE ARE HERE FOR YOU</h1>
            <p className="mb-4 text-center">At Luxury Hotels, we take our customers seriously. Do you have any enquiries, complaints or requests, please forward it to our support desk and we will get back to you as soon as possible.</p>
            <div className='text-left flex flex-col gap-2'>
              <div className='flex justify-center items-baseline gap-2'>
                <p><FaLocationDot /> </p>
                <p>Plantation - Thannott Road, near CUK Road, <br />Periye, Kerala 671316</p> 
              </div>
              <div className='flex gap-2 items-center'><FaPhoneVolume /><p>+91 94465 66663</p></div>
              <div className='flex items-center gap-2'><MdEmail /> <p>grouptenbusiness@gmail.com</p></div>
            </div>
          </div>
          <div className="md:w-1/2 w-full p-2 border">
            <form ref={formRef}>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">Name</label>
                <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="name" type="text" placeholder="Your name" name='name' onChange={handleChange} value={formData?formData.name : null}/>
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">Email Address</label>
                <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="email" type="email" placeholder="Your email" name="email" onChange={handleChange} value={formData?formData.email:null}/>
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="message">Message</label>
                <textarea className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="message" rows="4" placeholder="Your message" name="message" onChange={handleChange}>{formData?.message}</textarea>
              </div>
              <div className="flex items-center justify-center">
                <button className="bg-yellow-400 hover:bg-yellow-500 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit" onClick={mailSubmit}>
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
        <div>
        </div>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3896.8660843935118!2d75.0867097745381!3d12.39189642732379!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba487d9d06d8a05%3A0x147071e81e2ac1a!2sGroupten%20Plaza!5e0!3m2!1sen!2sin!4v1716742819810!5m2!1sen!2sin" width="100%" height="450" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </main>
      </div>}
      </div>
  )
}

export default ContactUs