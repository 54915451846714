import React, { useEffect, useState } from 'react'
import Hero from '../components/Hero'
import { CiCirclePlus } from "react-icons/ci";
import { getDmSection1, getDmSection2, getDmSection3 } from '../api/api';
import { useParams, useNavigate, redirect, Link } from 'react-router-dom';
import { ImageUrl } from '../api/common';
import ScrollAnimation from '../ScrollAnimation';
import BookingModal from '../components/BookingModel';
import Alert from '../components/Alert';
const DmPage = ({companyLists,match}) => {
    
    const [section1, setSection1] = useState([]);
    const [section2, setSection2] = useState(null);
    const [section3, setSection3] = useState(null);
    const [checkName, setCheckName] = useState(null)

    const [isOpen, setIsOpen] = useState(false);
    const [alert, setAlert] = useState({is_show: 0, type: null, message: null})
    const [apiCall1, setApiCall1] = useState(false);
    const [apiCall2, setApiCall2] = useState(false);
    const [apiCall3, setApiCall3] = useState(false);
    const [apiCall4, setApiCall4] = useState(false);

    let { name } = useParams();
    const navigate = useNavigate ();
    useEffect(()=>{
        if(companyLists.length > 0){
            const ck = companyLists.find((cl)=> cl.name == name )
            setCheckName(ck);
            if(!ck){
                navigate('/')
            }
        }
    },[companyLists])
    useEffect(() => {
        const fetchData = async () => {
          setApiCall1(true);
          try {
            const getSection1 = await getDmSection1(checkName.id);
            setSection1(getSection1);
            setApiCall1(false);
          } catch (error) {
            console.error(error);
            setApiCall1(false);
          }
        };
        fetchData();
      }, [checkName]);
      useEffect(() => {
        const fetchData = async () => {
          setApiCall2(true);
          try {
            const getSection2 = await getDmSection2(checkName.id);
            setSection2(getSection2);
            setApiCall2(false);
          } catch (error) {
            console.error(error);
            setApiCall2(false);
          }
        };
        fetchData();
      }, [checkName]);
      useEffect(() => {
        const fetchData = async () => {
          setApiCall3(false);
          try {
            const getSection3 = await getDmSection3(checkName.id);
            setSection3(getSection3);
            setApiCall3(false);
          } catch (error) {
            console.error(error);
            setApiCall3(false);
          }
        };
        fetchData();
      }, [checkName]);
      const mainLoading = apiCall1 || apiCall2 || apiCall3 || apiCall4;
  return (
    <div className='flex flex-col gap-6'>
        <Hero title1={section1?.title1} title2={section1?.title2} title3={section1?.title3} p1={section1?.description} is_book_now_btn={false} banners={section1} alert={alert} setAlert={setAlert} mainLoading={mainLoading}/>
        {section2?.section2Content.length > 0 && 
        <ScrollAnimation className='flex flex-col w-full items-center gap-6'>
            <h1 className='md:text-6xl text-2xl'>{section2.section2.title.toUpperCase()}</h1>
            <p className='w-5/6 text-center'>{section2.section2.description}</p>
            {section2?.section2Content.map((sc, key)=>{return(
            <ScrollAnimation className='w-full flex flex-col items-center' key={key}>
                <div className='w-full relative flex items-center justify-center'>
                    <img src={ImageUrl()+sc.image} alt="asdf" className='w-5/6 '/>
                    <div className='absolute bg-blue-800 text-white text-3xl w-5/6 text-center md:p-5 p-1 bottom-0'>
                        <h1 className='md:text-4xl text-xl'>{sc.title}</h1>
                    </div>
                </div>
                <div className='flex flex-col justify-center items-center w-5/6 border-8 md:p-10 p-3'>
                    <div className='w-full flex gap-10 justify-center items-center'>
                        <CiCirclePlus className='md:text-3xl hidden text-yellow-400 font-bold'/>
                        <p className='text-xl '>{sc.details}</p>
                    </div>
                    <div className='flex w-full justify-center'>
                      {checkName?.id == 2 ? 
                        <button className="mt-8 px-6 py-2 bg-yellow-500 text-black font-bold rounded hover:bg-yellow-600 hover:text-white items-center max-h-10" onClick={()=>setIsOpen(true)}>{checkName?.id == 2 ? "BOOK NOW" : "Contact Now"}</button>
                        : <Link to="/contact" ><button className="mt-8 px-6 py-2 bg-yellow-500 text-black font-bold rounded hover:bg-yellow-600 hover:text-white items-center max-h-10" onClick={()=>setIsOpen(true)}>{checkName?.id == 2 ? "BOOK NOW" : "Contact Now"}</button></Link>}
                    </div>
                </div>
            </ScrollAnimation>
            )})}
        </ScrollAnimation>
        }
        {section3?.section3Content.length > 0 && 
        
        <ScrollAnimation className='flex flex-col items-center w-full gap-5'>
            <h1 className='md:text-6xl text-2xl'>{section3.section3.title.toUpperCase()}</h1>
            <div className='flex flex-wrap w-full'>
            {section3.section3Content.map((sc, key)=>{return(
                <div className='flex items-center justify-center flex-col lg:w-1/3 md:w-1/2 w-1/1 relative p-2' key={key}>
                    <img src={ImageUrl()+sc.image} alt="" className='w-full'/>
                    <span className='absolute bg-white p-3 bottom-5 rounded-md'>{sc.title.toUpperCase()}</span>
                </div>
            )})}
            </div>
        </ScrollAnimation>}
            {checkName?.id == 2 && 
        <BookingModal setIsOpen={setIsOpen} isOpen={isOpen} alert={alert} setAlert={setAlert} setApiCall4={setApiCall4} apiCall4={apiCall4}/>}
    </div>
  )
}

export default DmPage