import React, { useState } from 'react';

const Alert = ({ alert, setAlert}) => {

  const alertTypeClasses = {
    success: 'bg-green-100 border-green-400 text-green-700',
    error: 'bg-red-100 border-red-400 text-red-700',
    warning: 'bg-yellow-100 border-yellow-400 text-yellow-700',
    info: 'bg-blue-100 border-blue-400 text-blue-700',
  };

  if (!alert.is_show) return null;

  return (
    <div className={`fixed top-10 right-0 mt-4 mr-4 border-l-4 p-4 z-50 ${alertTypeClasses[alert.type]} rounded-md shadow-lg`} role="alert">
      <div className="flex justify-between items-center">
        <div>
          <p className="font-bold">{alert.type.charAt(0).toUpperCase() + alert.type.slice(1)}</p>
          <p>{alert.message}</p>
        </div>
        <button onClick={() => setAlert({is_show: 0, type: null, message: null})} className="ml-4 text-black-700 hover:text-black-900">
          &times;
        </button>
      </div>
    </div>
  );
};

export default Alert;
