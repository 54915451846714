import React, { useState } from 'react'
import logo from '../assets/Home/logo.png';
import { Link } from 'react-router-dom';
import Alert from './Alert';

const Navbar = () => {
const [isMenuOpen, setIsMenuOpen] = useState(false);
var fullUrl = window.location.href;
var url = new URL(fullUrl);
var pathname = url.pathname;
var pageName = pathname.split('/').filter(Boolean).pop();
  return (
    <div>
        <div className="absolute top-0 flex items-start justify-between w-full mx-auto p-6 z-20">
          <img src={logo} alt="GroupTen Logo" className="w-20 mr-4" />
          <button 
            className="md:hidden text-white focus:outline-none"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d={!isMenuOpen ? "M4 6h16M4 12h16M4 18h16" : "M6 18L18 6M6 6l12 12"}></path>
            </svg>
          </button>
          <nav className={`hidden md:flex space-x-6 2xl:space-x-12 2xl:text-3xl ${isMenuOpen ? 'block' : 'hidden'} md:block`}>
            <Link to="/" className={`hover:text-yellow-400 ${!pageName && "text-yellow-400"}`}>Home</Link>
            {/* <Link to="" className="hover:text-yellow-400">Our Business</Link> */}
            <Link to="/about" className={`hover:text-yellow-400 ${pageName == "about" ? "text-yellow-400" : ""}`}>About us</Link>
            <Link to="/contact" className={`hover:text-yellow-400 ${pageName == "contact" ? "text-yellow-400" : ""}`}>Contact us</Link>
          </nav>
        </div>
        {isMenuOpen && (
          <nav className="md:hidden absolute top-16 left-0 right-0 bg-black bg-opacity-80 text-white flex flex-col items-center space-y-4 py-4 z-[1000]">
            <Link to="/" className={`hover:text-yellow-400 ${!pageName && "text-yellow-400"}`}>Home</Link>
            {/* <Link to="" className="hover:text-yellow-400">Our Business</Link> */}
            <Link to="/about" className={`hover:text-yellow-400 ${pageName == "about" ? "text-yellow-400" : ""}`}>About us</Link>
            <Link to="/contact" className={`hover:text-yellow-400 ${pageName == "contact" ? "text-yellow-400" : ""}`}>Contact us</Link>
          </nav>
        )}
    </div>
  )
}

export default Navbar