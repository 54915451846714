import React, { useEffect, useState } from 'react';
import gallery1 from '../assets/Home/gallery1.jpg'
import { getGallery } from '../api/api';
import { ImageUrl } from '../api/common';
const Gallery = () => {
  const [images, setImages] = useState([])
  useEffect(() => {
    const fetchData = async () => {
      try {
        const getImages = await getGallery();
        setImages(getImages);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);
  return (
    <div className="container mx-auto py-8 px-4">
      <h1 className="text-4xl font-bold text-center mb-8">Gallery</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {images.map((url, index) => (
          <div key={index} className="overflow-hidden rounded-lg shadow-lg group">
            <img 
              src={ImageUrl()+url.image} 
              alt={`Gallery image ${index + 1}`} 
              className="w-full h-full object-cover transition-transform duration-500 ease-in-out transform group-hover:scale-110 group-hover:rotate-3"
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default Gallery;
