import axios from 'axios';
const BASE_URL = 'https://grouptenadmin.idmsolution.in/api';

export const getCompanyList = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/get/company/list`);
      return response.data;
    } catch (error) {
      console.error("There was an error fetching the posts!", error);
      throw error;
    }
};
export const getDmSection1 = async (id) => {
  try {
    const response = await axios.get(`${BASE_URL}/get/section1?id=${id}`);
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the posts!", error);
    throw error;
  }
};

export const getDmSection2 = async (id) => {
  try {
    const response = await axios.get(`${BASE_URL}/get/section2?id=${id}`);
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the posts!", error);
    throw error;
  }
};
export const getDmSection3 = async (id) => {
  try {
    const response = await axios.get(`${BASE_URL}/get/section3?id=${id}`);
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the posts!", error);
    throw error;
  }
};
export const getBanner = async (id) => {
  try {
    const response = await axios.get(`${BASE_URL}/get/banner`);
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the posts!", error);
    throw error;
  }
};
export const getGallery = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/get/gallery`);
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the posts!", error);
    throw error;
  }
};
export const getAboutSection2 = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/about/get/section2`);
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the posts!", error);
    throw error;
  }
};
export const getAboutSection3 = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/about/get/section3`);
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the posts!", error);
    throw error;
  }
};
export const send_mail = async (data) => {
  try {
    const response = await axios.post(`${BASE_URL}/send/mail`, data);
    return response.data;
  } catch (error) {
    console.error("There was an error sending the mail!", error);
    throw error;
  }
};
export const send_contact_mail = async (data) => {
  try {
    const response = await axios.post(`${BASE_URL}/send/contact/mail`, data);
    return response.data;
  } catch (error) {
    console.error("There was an error sending the mail!", error);
    throw error;
  }
};