import React, { useEffect, useState } from 'react'
import Hero from '../components/Hero'
import Business from '../components/Business'
import Testimonials from '../components/Testimonials'
import Gallery from '../components/Gallery'
import banner from '../assets/Home/Hero.jpg';
import { getBanner } from '../api/api'
const Home = ({companyLists, apiCall2}) => {
  const [banners, setBanners] = useState([])
  const [apiCall1, setApiCall1] = useState(false)
  const [apiCall3, setApiCall3] = useState(false)
  const [isShowLoading, setIsShowLoading] = useState(false)
  useEffect(() => {
    setApiCall1(true);
    const fetchData = async () => {
      try {
        const getBanners = await getBanner();
        setBanners(getBanners);
        setApiCall1(false);
      } catch (error) {
        console.error(error);
        setApiCall1(false);
      }
    };
    fetchData();
  }, []);
  const mainLoading = apiCall1 || apiCall2 || apiCall3;
  return (
    <>
     <Hero title1="WELCOME TO" title2="GROUPTEN GROUP OF" title3="COMPANY" p1="Book your stay and enjoy Luxury redefined at the most affordable rates" is_book_now_btn={false} banners={banners} mainLoading={mainLoading}/>
      <Business companyLists={companyLists}/>
      {/* <Testimonials /> */}
      <Gallery setApiCall3={setApiCall3}/>
      </>
  )
}

export default Home