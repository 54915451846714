import React from 'react';
import { useInView } from 'react-intersection-observer';

const ScrollAnimation = ({ children, className, ...props }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <div
      ref={ref}
      className={`${className} transition-opacity duration-1000 ${
        inView ? 'opacity-100' : 'opacity-0'
      }`}
      {...props}
    >
      {children}
    </div>
  );
};

export default ScrollAnimation;
