import React, { useEffect, useState } from 'react';
import Header from './components/Header';
import Hero from './components/Hero';
import Business from './components/Business';
import Testimonials from './components/Testimonials';
import Footer from './components/Footer';
import Gallery from './components/Gallery';
import Home from './pages/Home';
import AboutUs from './pages/AboutUs.jsx';
import ContactUs from './pages/ContactUs.jsx';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import DmPage from './pages/DmPage.jsx';
import { getCompanyList } from './api/api.js';



const App = () => {
  const [companyLists, setCompanyLists] = useState([])
  const [apiCall2, setApiCall2] = useState(false)

useEffect(() => {
  const fetchData = async () => {
    setApiCall2(true);
    try {
      const companyLists = await getCompanyList();
      setCompanyLists(companyLists);
      setApiCall2(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setApiCall2(false);
    }
  };

  fetchData();
}, []);
  return (
    
    <div className=''>
      <BrowserRouter>
        {/* <Header /> */}
        <Routes>
          <Route path="/" element={<Home companyLists={companyLists} apiCall2={apiCall2}/>} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/dm/:name" element={<DmPage companyLists={companyLists}/>} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
};

export default App;
