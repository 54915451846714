import React, { useEffect, useState } from 'react';

import business1 from "../assets/Home/business1.jpg"
import business2 from "../assets/Home/business2.jpg"
import business3 from "../assets/Home/business3.jpg"
import business4 from "../assets/Home/business4.jpg"

import ScrollAnimation from'../ScrollAnimation';
import { ImageUrl } from '../api/common';
import { getCompanyList } from '../api/api';
import { Link } from 'react-router-dom';

// const business = [
//   {id: 1, title: "Groupten Plaza", image: business1, description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Obcaecati natus, vel eveniet odio ut necessitatibus placeat, sit iure sequi omnis ea? Placeat ducimus, optio voluptates commodi odio reprehenderit accusantium quas!"},
//   {id: 2, title: "Groupten Builders and Developers", image: business2, description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Obcaecati natus, vel eveniet odio ut necessitatibus placeat, sit iure sequi omnis ea? Placeat ducimus, optio voluptates commodi odio reprehenderit accusantium quas!"},
//   {id: 3, title: "Groupten Apartment and Homestay", image: business3, description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Obcaecati natus, vel eveniet odio ut necessitatibus placeat, sit iure sequi omnis ea? Placeat ducimus, optio voluptates commodi odio reprehenderit accusantium quas!"},
//   {id: 4, title: "Groupten Builders and RealEstate", image: business4, description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Obcaecati natus, vel eveniet odio ut necessitatibus placeat, sit iure sequi omnis ea? Placeat ducimus, optio voluptates commodi odio reprehenderit accusantium quas!"},
// ];

const Business = ({companyLists}) => {
  

  return (
    <section className="container mx-auto p-4 my-16">
      <h2 className="text-3xl font-bold text-center mb-12 text-[#2b4081]">Group of Business</h2>
      {/* <div className="grid grid-cols-1 md:grid-cols-2 gap-8"> */}
      <div className="flex flex-col gap-8">
        {companyLists?.map((bs, key)=>{return(
        <ScrollAnimation className="bg-white rounded-lg shadow-md overflow-hidden flex flex-col lg:flex-row-reverse w-10/12 items-center justify-center mx-auto text-center lg:text-left" key={key}>
          <img src={ImageUrl()+bs.image} alt="Luxury room" className="w-full max-w-md h-72 object-cover"/>
          <div className="md:p-6 p-1 w-3/4 mx-auto flex items-center justify-center flex-col">
            <h3 className="text-2xl font-bold mx-auto w-full lg:w-3/4 ">{bs.name}</h3>
            <p className="mt-4 w-full lg:w-3/4 mx-auto">{bs.description}</p>
            <Link to={"/dm/"+bs.name}>
            <button className="mt-10 px-6 py-2 bg-yellow-500 text-black font-bold rounded">Explore</button>
            </Link>
          </div>
        </ScrollAnimation>
        )})}
      </div>
    </section>
  );
};

export default Business;
